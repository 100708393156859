import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError, getValueId } from "../../../../theme/helpers";
import { KTButton, SearchTable } from "../../../../theme/partials";
import { useAuth } from "../../../modules/auth";
import { useStoreMode } from "../../../modules/auth/core/store";
import { popupMessage } from "../../../modules/messages";
import { checkPaid } from "./activeOrder";
import { ModalAddEditOrder } from "./addOrder";
import { cancelOrder, converDataWithCustom, handleClickGetItemKOT } from "./functionOrder";
import { DetailItem, HeadItemTemplate, MemberInforAndNote, NoDataTemplate, PromotionAndSum } from "./templateOrder";
import { Trans, useTranslation } from "react-i18next";
const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});
const badgeSearch = [{
    label:  <Trans i18nKey="orders-hub-deep.not-paid"/>,
    value: "notPaid",
},
{
    label:  <Trans i18nKey="orders-hub-deep.paid"/>,
    value: "isPaid",
},
{
    label: <Trans i18nKey="orders-hub-deep.dinner-in"/>,
    value: "dinner_in",
},
{
    label: <Trans i18nKey='orders-hub-deep.curbside'/>, //"Cubsude",
    value: "is_curbside",
},
{
    label: <Trans i18nKey='orders-hub-deep.delivery'/>,
    value: "is_delivery",
},
{
    label: <Trans i18nKey='orders-hub-deep.no-behavior'/>,
    value: "no_behavior",
},
{
    label: <Trans i18nKey='orders-hub-deep.takeout'/>,
    value: "takeout_in",
},
]
const urlApi = 'settings/customers'
const MLive = (props:any) => {
    const { t } = useTranslation();
    
    const {dataApi,filter,setFilter, isSearch, setSearchOn,} = props
    // const { permission } = getLocationData()
    const [note,setNote] = useState('')
    const callBackReFetch = () => {
        handleClickGetItemKOT(dataQuery[orderActive],setDataActive,setNote)
    }
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}

    const navigate = useNavigate();
    const { id } = useParams()
    const { valueid } = getValueId(id)
    const { currentUser } = useAuth() as any
    const [dataQuery ,setDataQuery] = useState<any>([])
    const [orderActive ,setOrderActive] = useState<any>(0)
    const [edit ,setEdit] = useState<any>(false)
    const [dataModal ,setDataModal] = useState<any>({})
    
    useEffect(() => {
        if(dataApi) {
            // console.log('call getData')
            getData(dataApi||[])
        }
    }, [dataApi,dataApi.modifiedat])
    useEffect(() => {
        if(props.filter) {
            // console.log('filter',props.filter)
        }
    }, [props.filter])
    const getData = (data: any) => {
        data.forEach((item: any,index: number) => {
            item = converDataWithCustom(item,item.transactionstatus)
        })
        if(data.length == 0) {
            setDataActive(null)
            setOrderActive(-1)
        }
        else if(!dataActive || ( dataActive && dataActive.id != data[0]?.id)) {
            handleClickGetItemKOT(data[0],setDataActive,setNote)
            setOrderActive(0)
        }
        setDataQuery(data)
    
    }
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
    })

    const toolbar = <>
        <KTButton action="back" onClick={() => navigate(-1)}/>
    </>

    
    const funcCallback = async(item: any, index: number, status: string) => {
        let tempData = dataQuery.map((i: any) => i)
        // tempData[index] = converDataWithCustom(item,status)
        try {
            const urlApi = `smarthub/hosptransactions/update/${item.id}`
            let tempApproved = {
                transactionstatus: status,
                staffcode: staffInfo.code,
                staffreceiptname: staffInfo.nameonreceipt,
                HospTransactionLines: dataActive?.HospTransactionLines?.filter((i: any) => i.kitchenstatus == 'Not Sent').map((i: any) => {
                    return {
                        ...i
                    }
                })
            }
            await ApiService.put(urlApi, tempApproved)
            // callBackReFetch()
            // tempData = tempData.filter((i: any,ind: number) => ind != index)
            // setDataQuery(tempData)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }

    const checkSearch = (value: any) => {
        return filter[value]
    }
    
    const [dataActive,setDataActive] = useState<any>(null)
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}

    return <>
    <div className="row">
        <div className="col-7 ps-0">
        <form
            className="h-100 w-100"
            onSubmit={handleSubmit(async (data: any) => {
                console.log('onsubmit',data)
                return
                if(data.birthday) {
                    data.birthday = new Date(data.birthday[0])
                }
                if(data.joinDate) {
                    data.joinDate = new Date(data.joinDate[0])
                }
                if(data.officialStartDate) {
                    data.officialStartDate = new Date(data.officialStartDate[0])
                }
                let fullName = data.fistName?.trim()+' '+data.middleName?data.middleName?.trim()+' ':''+data.lastName?.trim()
                // data.fullName = fullName && fullName
                // data.flag = valueid ? 'Update' : 'Insert'
                // data.keyID = keyID
                let tempData = {}
                try {
                    const urlApi = `HR_Employee/InsertUpdHR_Employee/`
                    await ApiService.post(urlApi, tempData)
                    popupMessage({ icon: 'success', autoClose: true })
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="wrap-content-card h-100">
                {isSearch && <div className="position-relative mt-4">
                    
                    <SearchTable data={dataQuery} setTableData={setDataQuery} />
                    <span className="cursor-pointer close-event-s" onClick={e => setSearchOn(false)}>{t('orders-hub-deep.cancel')}</span>
                </div>
                }
                {filter &&
                    <div className="card-header min-h-auto pt-4 d-flex gap-2">
                        {
                            badgeSearch.map((item: any) => (
                                checkSearch(item.value) && 
                                <span className="badge badge-gray d-inline-flex flex-center py-2 gap-1"><span>{item.label}</span> <i onClick={e=>{
                                    let a = JSON.parse(JSON.stringify(filter))
                                    a[item.value] = false
                                    setFilter(a)
                                }} className="bi bi-x cursor-pointer"></i></span>
                            ))
                        }
                        
                    </div>
                }
                {
                    dataQuery && dataQuery.length != 0 ?
                    <div className="card-body pb-0 pt-4">
                        {dataQuery?.map((item: any,index: number) =>{
                            return <div className="wrap-item" key={index} onClick={e=>{
                                setOrderActive(index)
                                handleClickGetItemKOT(item,setDataActive,setNote)
                            }}>
                                {
                                    <HeadItemTemplate item={item} index={index} orderActive={orderActive}/>
                                }
                            
                        </div>
                        })}
                    </div>
                    :
                    <NoDataTemplate className={'h-100 pt-4'}/>
                }
                
            </div>
            
            
            
        </form>
        </div>
        <div className="col-5 right-detail px-0">
            {dataActive && orderActive > -1 ? 
                <div className="p-0 card shadow pt-3">
                    <h6 className="d-flex flex-stack px-3">
                        <span>{dataActive.tablename} - {dataActive.sectionname}</span>
                        {
                           (staffPermissionGroupInfo.isvoidline || (staffPermissionGroupInfo.isvoidtransaction && !checkPaid(dataActive).paided)) && 
                            <span onClick={e=>cancelOrder(dataActive,orderActive,dataQuery,setDataQuery, staffInfo)} className="btn btn-sm btn-icon btn-light btn-active-light-danger"><i className="bi bi-x-lg"></i></span>
                        }
                    </h6>
                    <DetailItem className="wrap-hub-details" note={note||dataActive.message} {...dataActive} />
                    <div className="wrap-footerItem">
                        <MemberInforAndNote note={note||dataActive.message} {...dataActive} setDataModal={setDataActive} />
                        <PromotionAndSum {...dataActive}/>
                        
                        <div className="d-flex gap-4 mt-3 px-3">
                            <button className="btn btn-sm btn-success w-50"><i className="bi bi-printer me-1"></i>{t('order-deep.print')}</button>
                            {
                                staffPermissionGroupInfo.isopensalepos &&
                                <button onClick={e=>{
                                setDataModal(dataActive)
                                setEdit(true)
                                }} className="btn btn-sm btn-primary w-50"><i className="bi bi-pencil me-1"></i>{t('orders-hub-deep.edit')}</button>
                            }
                            
                        </div>
                        <div className="p-3">
                            <button onClick={e => {
                                funcCallback(dataActive,orderActive,dataActive.nextStatus)
                            }} className="btn btn-sm btn-info w-100"><i className="bi bi-printer me-1"></i>{t('orders-hub-deep.approve')}</button>
                        </div>
                    </div>
                    
                </div>
                :
                <NoDataTemplate className={'h-100 pt-4'}/>
            }
            
        </div>
    </div>
    {
        edit && dataModal.id &&
        <ModalAddEditOrder funcCallback={funcCallback} show={edit} dataModal={dataModal} setDataModal={setDataModal} setShow={setEdit} refetchCurrent={callBackReFetch} />

    }
        
    </>
}

export { MLive };

