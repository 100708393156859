import { useEffect, useRef, useState } from "react";
import { ApiService, ApiShowError, formatCurrent, sortByNumber } from "../../../../theme/helpers";
import { popupLoading, popupMessage, popupQuestion } from "../../../modules/messages";
import { Trans } from "react-i18next";
import { KITCHENSTATUS } from "./templateOrder";
import { findAndPrint } from "../../mode/print";



export function add15MinutesToTime(inputTime: string) {
    // Chuyển đổi thời gian đầu vào thành đối tượng Date
    var inputDate = new Date("2022-01-01 " + inputTime);

    // Cộng thêm 15 phút
    inputDate.setMinutes(inputDate.getMinutes() + 15);

    // Định dạng thời gian mới
    var hours = inputDate.getHours() as any;
    var minutes = inputDate.getMinutes() as any;
    var seconds = inputDate.getSeconds() as any;

    // Đảm bảo định dạng có hai chữ số
    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;

    // Tạo chuỗi thời gian mới
    var newTime = hours + ':' + minutes + ':' + seconds;

    // Trả về thời gian mới
    return newTime;
}
export function timeSince(date:any) {
    let today = new Date().getTime()
    // console.log('today - date',today - date)
    var seconds = Math.floor((today - date) / (1000));
    var interval = seconds / (24*3600);
    if (interval > 1) {
      return <Trans i18nKey={'number-days'} values={{number: Math.floor(interval)}} />
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return <Trans i18nKey={'number-hours'} values={{number: Math.floor(interval)}} />
    }
    interval = seconds / 60;
    if (interval > 1) {
     return <Trans i18nKey={'number-minutes'} values={{number: Math.floor(interval)}} />
    }
    interval = interval < 0? 0 : interval
   return <Trans i18nKey={'number-seconds'} values={{number: Math.floor(interval)}} />
  }
export const converDataWithCustom = (item: any, status: string) => {
    if(status == 'Open'||status == 'Schedule') {
        item.transactionstatus = status
        item.headerbadge = 'orange'
        item.nextStatus = 'Active'
    }
    if(status == 'Active') {
        item.transactionstatus = status
        item.headerbadge = 'primary'
        item.nextStatus = 'Ready'
    }
    if(status == 'Ready') {
        item.transactionstatus = status
        item.headerbadge = 'primary'
        item.nextStatus = 'Completed'
    }
    if(status == 'Completed') {
        item.transactionstatus = status
        item.headerbadge = 'primary'
        item.nextStatus = 'Open'
    }
    // if(status == 'Ready-to-serve') {
    //     item.transactionstatus = status
    //     item.actionTextRight = 'Sent'
    //     item.badgeRight = 'info'
    //     item.headerbadge = 'primary'
    //     item.nextStatus = 'Served'
    // }
    if(status == 'Served' || status == 'Cancel') {
        item.transactionstatus = status
        item.actionTextLeft = 'Ready'
        item.badgeLeft = 'success'
        item.actionTextRight = 'Served'
        item.badgeRight = 'warning'
        item.headerbadge = 'gray'
    }
    return item
}
export const cancelOrder = async(item: any,index: number,dataQuery: any, setDataQuery: any,staffInfo: any) => {
    let asw = await popupQuestion({title:'Do you want to cancel this order?'})
    if(asw) {
        try {
            const urlApi = `smarthub/hosptransactions/update/${item.id}`
            let temp = {
              transactionstatus: 'Cancel',
              staffcode: staffInfo.code, 
              staffreceiptname: staffInfo.fullname,
            }
            await ApiService.put(urlApi, temp)
            let tempData = dataQuery.map((i: any) => i)
            tempData = tempData.filter((i: any,ind: number) => ind != index)
            setDataQuery(tempData)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }
}
export function removeVietnameseAndSpecialChars(inputString: string, space?: string) {
    if (!inputString) return '';
    var AccentsMap = ['aàảãáạăằẳẵắặâầẩẫấậ', 'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ', 'dđ', 'DĐ', 'eèẻẽéẹêềểễếệ', 'EÈẺẼÉẸÊỀỂỄẾỆ', 'iìỉĩíị', 'IÌỈĨÍỊ', 'oòỏõóọôồổỗốộơờởỡớợ', 'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ', 'uùủũúụưừửữứự', 'UÙỦŨÚỤƯỪỬỮỨỰ', 'yỳỷỹýỵ', 'YỲỶỸÝỴ',]
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substring(1) + ']', 'g')
      var char = AccentsMap[i][0]
      inputString = String(inputString).replace(re, char).toLowerCase()
    }
    return inputString
      .replace(/[^\w\s]/gi, '') // loại bỏ các ký tự đặc biệt
      .replace(/\s+/g, ' ') // gộp các khoảng trắng liên tiếp
      .trim()
      .normalize("NFD") // Chuyển về Unicode Normalization Form D
      .replace(/[\u0300-\u036f]/g, "")  // Loại bỏ dấu tiếng Việt
      .replace(/[^a-zA-Z0-9.]/g, space || "") // loại bỏ chữ cái tiếng việt + space;
      .replace(/^-+|-+$/g, '') // + loại bỏ nếu có space ở 2 đầu
  }
export function isArrayNotEmpty(arr?: any) {
    if (arr === undefined) return false;
    return Array.isArray(arr) && arr.length > 0;
}
export function debounce(fn: Function, ms = 300) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};
export function searchWithKeyword(arr: any[] | undefined, keyword: string, fields: string[]) {
    if (!arr || !isArrayNotEmpty(arr)) return [];
    if (!keyword) return arr;
    const keywordNotAccents = removeVietnameseAndSpecialChars(keyword)

    return arr.filter((item) => {
        for (let i = 0; i < fields.length; i++) {

            const field = fields[i];
            const isDot = field.includes('.');
            let text = String(item[fields[i]]);

            if (isDot) {
                const fieldsDot = field.split('.');
                let obj = item;
                for (let j = 0; j < fieldsDot.length; j++) {
                    obj = obj[fieldsDot[j]];
                }
                text = String(obj);
            }
            if (!text) return false;
            if (text == "null") return false;
            const fieldNotAccents = removeVietnameseAndSpecialChars(text)
            if (fieldNotAccents.includes(keywordNotAccents)) return true;
        }
        return false;
    });
}

export const CountUpTimer = (props: any) => {
    let {starttime} = props
    const [millisecondsElapsed, setMillisecondsElapsed] = useState(0);
    const [intervalId, setIntervalId] = useState<any>(null);
    useEffect(()=>{
        let time = new Date().getTime() - starttime
        startTimer()
        setMillisecondsElapsed(time)
       
    },[])
    
    const startTimer = () => {
      if (!intervalId) {
        setIntervalId(
          setInterval(() => {
            setMillisecondsElapsed((prevMilliseconds: any) => prevMilliseconds + 10);
          }, 10)
        );
      }
    };
  
    const pauseTimer = () => {
      clearInterval(intervalId);
      setIntervalId(null);
      setMillisecondsElapsed(0)
    };
  
    
    // console.log('formatValue(millisecondsElapsed)',formatValue(millisecondsElapsed))
    return <>{formatValue(millisecondsElapsed)}</>
  };
export const formatValue = (value: any) => {
  const hours = Math.floor(value / 3600000);
  const minutes = Math.floor((value % 3600000) / 60000);
  const seconds = Math.floor((value % 60000) / 1000);
  if (hours >= 24) {
    let day = Math.floor(value / (24*3600000));
    return <Trans i18nKey={"number-days"} values={{number: day}} />
  }
  return `${hours>=1 ? `${hours.toString().padStart(2, "0")}:`:''}${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
export const handleClickGetItemKOT = async(item: any,setDataActive: any, setNote: any, orderActive?: any, dataQuery?: any, setDataQuery?: any) => {
  const urlApi = `smarthub/hosptransactions/findactive/${item.id}`
  let {data} = await ApiService.get(urlApi)
  data = converDataWithCustom(data,data.transactionstatus)
  if(data.kots && data.kots.length!=0) {
    setNote(data.kots[0]?.comments)
  }
  else {
    setNote(data.message)
  }
  setDataActive(data)
  if(orderActive && setDataQuery && dataQuery) {
    let a = dataQuery
    a[orderActive] = data
    setDataQuery(a)
  }
  return data
}
export const updateOnERP = async(dataModal: any,setDataModal?: any,tempLines?: any) => {
  try {
    const urlApi = `lscentral/hosporders/updatehosporder`//`lscentral/hosporders/upserttransaction2`
    // let HospTranLines = JSON.parse(JSON.stringify((tempLines||dataModal.HospTransactionLines).filter((i: any) => i.kitchenstatus != KITCHENSTATUS.voided)))
    // console.log('HospTranLines',HospTranLines)
    // if(HospTranLines.length == 0) {
      
    // }
    // let tempHospTranLines = [] as any
    // HospTranLines.forEach((i: any) => {
    //     let a = [{
    //         id: i.id,
    //         lineno: i.lineno,
    //         itemno: i.itemno,
    //         quantity: i.quantity,
    //         entrytype: i.entrytype,
    //         barcodeno: i.barcodeno,
    //         kitchenstatus: i.kitchenstatus,
    //         customizations: i.customizations,
    //     }]
    //     let b = i.customizations?.map((i: any) => ({
    //         id: i.id,
    //         lineno: i.lineno,
    //         itemno: i.itemno,
    //         quantity: i.quantity,
    //         entrytype: i.entrytype,
    //         barcodeno: i.barcodeno,
    //         kitchenstatus: i.kitchenstatus,
    //     }))
    //     let c = a.concat(b)
    //     tempHospTranLines = tempHospTranLines.concat(c)
    // })
    
    let tempData = {
        transactionid: dataModal.id,

    }
    // console.log('dataModal',dataModal)
    // return
    let {data} = await ApiService.post(urlApi, tempData)
    
    
} catch (error) { ApiShowError(error) }
}
export const createOnERP = async(dataModal: any,setDataModal?: any,tempLines?: any) => {
  try {
      const urlApi = `lscentral/hosporders/updatehosporder`//`lscentral/hosporders/upserttransaction2`
      let HospTranLines = JSON.parse(JSON.stringify((tempLines||dataModal.HospTransactionLines).filter((i: any) => i.kotline?!i.kotline.isvoided:true)))
      let tempHospTranLines = [] as any
      HospTranLines.forEach((i: any) => {
          let a = [{
              id: i.id,
              lineno: i.lineno,
              itemno: i.itemno,
              quantity: i.quantity,
              entrytype: i.entrytype,
              barcodeno: i.barcodeno,
              kitchenstatus: i.kitchenstatus,
              customizations: i.customizations,
          }]
          let b = i.customizations?.map((i: any) => ({
              id: i.id,
              lineno: i.lineno,
              itemno: i.itemno,
              quantity: i.quantity,
              entrytype: i.entrytype,
              barcodeno: i.barcodeno,
              kitchenstatus: i.kitchenstatus,
          }))
          let c = a.concat(b)
          tempHospTranLines = tempHospTranLines.concat(c)
      })
      
      let tempData = {
          id: dataModal.id,
          HospTransactionLines: tempHospTranLines,
          tableid: dataModal.tableid,
          tableno: dataModal.tableno,
          tablename: dataModal.tablename,
          receiptno: dataModal.receiptno,
          storeno: dataModal.storeno,
          hospitalitytype: dataModal.hospitalitytype,
          terminalno: dataModal.terminalno,
          customerinfo: dataModal.customerinfo,
          code: dataModal.code,
          transactiondate: dataModal.transactiondate,
          transactiontime: dataModal.transactiontime,

      }
      // console.log('dataModal',dataModal)
      // return
      let {data} = await ApiService.post(urlApi, tempData)
      let {updatedTransaction, updatedTransactionlines} = data
      if(updatedTransaction && updatedTransactionlines)  {
          let idsTrans = updatedTransactionlines.map((i: any) => i.id)
          let tempItems = dataModal.HospTransactionLines
          let tempHospTransactionLines = tempItems.filter((i: any) => !idsTrans.includes(i.id))
          updatedTransactionlines = updatedTransactionlines.map((i: any) => {
              let record = tempItems.find((t: any) => i.id == i.id)||{}
              return {...i, HospItem: record.HospItem}
          })
          let transLine = tempHospTransactionLines.concat(updatedTransactionlines)
          let tempDataModal = {
              ...updatedTransaction,
              HospTransactionLines:  sortByNumber(transLine,'lineno')
          }
          // console.log('tempDataModal',tempDataModal)
          if(tempLines) {
            setDataModal && setDataModal(tempDataModal)
          }
      }
      
  } catch (error) { ApiShowError(error) }
}
export const cancelOrderInModal = async(item: any,refetch: any,callPrint: any, staffInfo: any) => {
  let asw = await popupQuestion({title:'Do you want to clear this order?'})
  if(asw) {
      callPrint && callPrint()
      try {
          popupLoading()
          const urlApi = `smarthub/hosptransactions/update/${item.id}`
          let tempData = {
            transactionstatus: 'Cancel',
            staffcode: staffInfo.code, 
            staffreceiptname: staffInfo.fullname,
          } 
          await ApiService.put(urlApi, tempData)
          // refetchTable && refetchTable()
          refetch && refetch()
          // refetchOrderService()
          popupMessage({ icon: 'success', autoClose: true })
      } catch (error) { ApiShowError(error) }
  }
}
export const getColorTrans = (item: any, transColors: any) => {
  let a = transColors?.find((i:any)=>i.code == item?.code)
  if(a) {
      return a.color
  }
  return null
}
export const callLockTable = async(tempData: any, setIsCallLock: any) =>{
  // 
  let urlApi = `smarthub/hosptransactions/lockorreleasetableupdating`
  try {
      await ApiService.post(urlApi, tempData)
      if(tempData.lockinfo) {
        setIsCallLock(true)
      }
      else {
        setIsCallLock(false)
      }
  } catch (error) { 
    ApiShowError(error) 
  }

}