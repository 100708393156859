import { yupResolver } from '@hookform/resolvers/yup';
import { type Container, type ISourceOptions, MoveDirection, OutMode, } from "@tsparticles/engine";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { redirect, useNavigate } from 'react-router-dom';
import { ApiService, ApiShowError, encryptData, setCookie } from '../../../../theme/helpers';
import { InputSelect, KTFormItem, KTSVG } from '../../../../theme/partials';
import { framePermission } from '../Permission';
import { useAuth } from '../core/Auth';
import { useStore } from '../core/store';
import './login.css';
import { Modal } from 'react-bootstrap';
import * as yup from 'yup'
import { useSiteContext } from '../../../../theme/SiteProvider';
import { useTranslation } from 'react-i18next';

export function Login() {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    // console.log(container);
  };

  const options: ISourceOptions = useMemo(
    () => ({

      fullScreen: {
        enable: true,
        zIndex: 1
      },
      style: {
        position: 'fixed',
        top: '65%',
        bottom: '0',
        left: '0',
        right: '0',
      },
      // background: {
      //     color: {
      //         value: "#0d47a1",
      //     },
      // },
      fpsLimit: 60,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#000",
        },
        links: {
          color: "#000",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outModes: {
            default: OutMode.out,
          },
          random: true,
          speed: 3,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 200,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    } as ISourceOptions),
    [],
  );

  const form = useForm({
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(yup.object().shape({
      username: yup.string().required('Username is required'),
      password: yup.string().required('Password is required'),
    })),
  })

  const navigate = useNavigate();
  const { saveAuth } = useAuth()
  const initMenu = useStore((state: any) => state.fetchMenu)
  const [viewPass, setViewPass] = useState(false)
  const [open, setOpen] = useState(false)

  function getPermissions(datas: any) {
    let permissions = [] as any
    datas?.forEach((item: any) => {
      let tempPermission = [] as any
      framePermission?.forEach((i: any) => {
        if (item[i.code]) tempPermission.push(i.value)
      })
      let temp = {
        code: item.objectid,
        permissions: tempPermission
      }
      permissions.push(temp)
    })
    permissions = JSON.stringify(permissions) as any
    permissions = encryptData(permissions) as any
    return permissions
  }

  const [merchantKey, setMerchantKey] = useState<any>()
  const [optionSelectStore, setOptionSelectStore] = useState<any>([])

  const onSubmit = async (values: any) => {
    try {
      let bodyData = { ...values }
      if (merchantKey) {
        bodyData['merchantkey'] = merchantKey
      }
      let { data } = await ApiService.post(`auth/login${!!merchantKey ? 3 : 2}`, bodyData)
      if (data && Array.isArray(data)) {
        setOpen(true)
        setOptionSelectStore(data.map((item: any) => {
          return {
            ...item,
            value: item.merchantkey,
            label: item.storecode + ' - ' + item.storename
          }
        }))
        setMerchantKey(data[0].merchantkey)
      } else {
        data.user.rolecode = encryptData(data.user?.rolecode)
        setCookie('token',data.accessToken,365)
        saveAuth({ user: data.user, permissions: getPermissions(data.permissions) })
        initMenu(data?.username)
        // console.log('aaaaaaaaaaa')
        navigate('/mode')
        // redirect("/mode");
      }
    } catch (error) {
      ApiShowError(error)
    }
  }

  const loginWithMicrosoft = async () => {
    try {
      let env = ''
      if (process.env.REACT_APP_LOCAL != 'false') {
        env = '&env=local'
      }
      const result = await ApiService.get(`auth2/authorize?${env}`)
      window.location.href = result.data
    } catch (error) {
      ApiShowError(error)
    }
  }

  const { t, i18n } = useTranslation()
  const optionLocales = [
    { value: 'en', label: <span><KTSVG path='/media/svg/flags/uk.svg' className='me-2 h-20px' />ENG</span> },
    { value: 'vi', label: <span><KTSVG path='/media/svg/flags/vietnam.svg' className='me-2 h-20px' />VIE</span> }
  ]

  return <>
    <div className="h-100 d-flex flex-column align-items-center position-relative" style={{ background: '#F2F2F2' }}>
      <div className='position-absolute end-0 top-0 pt-10 pe-10' style={{ zIndex: 20 }}>
        <InputSelect
          options={optionLocales}
          value={optionLocales.find(f => f.value == i18n.resolvedLanguage) ?? null}
          onChange={(option) => {
            i18n.changeLanguage(option.value ?? 'en')
          }}
        />
      </div>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-375px d-flex flex-column align-items-center"
        style={{ zIndex: 10, background: '#F2F2F2', maxWidth: '90%', paddingTop: "5%" }}
      >
        <div className="logo-login">
          <img src="/media/images/logo_sp_trungnguyenlegend.png" alt="Logo" />
        </div>
        <div className="fs-3 fw-bold mb-3" style={{ fontSize: '1.8rem', paddingTop: '15px' }}>{t('login-deep.merchant-console')}</div>
        <div className='position-relative w-100 mb-2'>
          <input {...form.register('username')} autoComplete='off' className="input-login ps-10" type="text" placeholder={t('login-deep.username')} />
          <div className='position-absolute start-0 top-0 ps-2 pt-3 text-gray-800'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user-round"><circle cx="12" cy="8" r="5" /><path d="M20 21a8 8 0 0 0-16 0" /></svg>
          </div>
        </div>
        {form.formState.errors.username
          && <div className='text-danger w-100'>{form.formState.errors.username.message}</div>}
        <div className='position-relative w-100 mb-4 mb-2'>
          <input {...form.register('password')} type={viewPass ? 'text' : 'password'} autoComplete='off' className="input-login ps-10" placeholder={t('login-deep.password')} />
          <div className='position-absolute start-0 top-0 ps-2 pt-3 text-gray-800'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-lock"><rect width="18" height="11" x="3" y="11" rx="2" ry="2" /><path d="M7 11V7a5 5 0 0 1 10 0v4" /></svg>
          </div>
          {!(form.formState.touchedFields.password && form.formState.errors.password)
            && <div className='cursor-pointer view-password' onClick={e => setViewPass(!viewPass)}>
              <i className={clsx(`fs-2 bi bi-eye${viewPass ? '-slash' : ''}`)} style={{ color: '#ccc' }}></i>
            </div>}
        </div>
        {form.formState.errors.password
          && <div className='text-danger w-100'>{form.formState.errors.password.message}</div>}
        <button disabled={form.formState.isSubmitting} type="submit" className="btn-login mt-4">
          {t('login')}
        </button>
        <div className="d-flex gap-2 align-items-center w-100 my-2">
          <hr className="w-100" />
          <span className=''>{t('login-deep.or')}</span>
          <hr className="w-100" />
        </div>
        <button disabled={form.formState.isSubmitting} type="button" className="btn-login-microsoft" onClick={loginWithMicrosoft}>
          <img src="/media/svg/social-logos/icons8-microsoft.svg" alt="microsoft login" /> {t('login-deep.login-with-microsoft')}
        </button>
      </form>
      {init && <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />}
    </div>
    <footer className="position-fixed start-0 end-0 bottom-0 text-center p-6" style={{ background: 'rgba(255,255,255,0.5)', backdropFilter: 'blur(3px)' }}>{t('login-deep.copyrights')} © 2023 <span style={{ fontWeight: 'medium' }}>iSOFT</span></footer>
    <Modal
      show={open}
      centered
      backdrop="static"
      keyboard={false}
      className="backdrop-filter-blur-50"
      onHide={() => setOpen(false)}
    >
      <Modal.Body className="bg-gray-200 rounded">
        <KTFormItem title={t('login-deep.select-store')} isRequired>
          <InputSelect
            options={optionSelectStore}
            value={optionSelectStore.find((f: any) => f.value == merchantKey) ?? null}
            onChange={(e) => {
              setMerchantKey(e?.value ?? null)
            }}
          />
        </KTFormItem>
        <div className='text-end d-flex gap-4 flex-end'>
          <button
            onClick={e => {
              setOpen(false)
              setMerchantKey(null)
            }}
            className="btn btn-sm btn-secondary w-100px min-w-100px">
            {t('login-deep.cancel')}
          </button>
          <button className="btn btn-sm btn-primary w-100px min-w-100px" onClick={() => onSubmit(form.watch())}>
            {t('login')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  </>
}
