import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError, formatCurrent, getValueId } from "../../../../theme/helpers";
import { FilterDropdown, KTButton, KTSVG, SearchTable } from "../../../../theme/partials";
import { useAuth } from "../../../modules/auth";
import { popupMessage, popupQuestion } from "../../../modules/messages";
// import { add15MinutesToTime, converDataWithCustom } from "./functionOrder";
import { Dropdown } from "react-bootstrap";
import { useStoreMode } from "../../../modules/auth/core/store";
import { checkPaid } from "../../orderManagement/tabs/activeOrder";
import { ModalAddEditOrder } from "../../orderManagement/tabs/addOrder";
import { CountUpTimer, converDataWithCustom, handleClickGetItemKOT } from "../../orderManagement/tabs/functionOrder";
import { DetailItem, ModalPay, NoDataTemplate, PromotionAndSum } from "../../orderManagement/tabs/templateOrder";
import { Trans, useTranslation } from "react-i18next";
const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});
const badgeSearch = [{
    label:  <Trans i18nKey="orders-hub-deep.not-paid"/>,
    value: "notPaid",
},
{
    label:  <Trans i18nKey="orders-hub-deep.paid"/>,
    value: "isPaid",
},
{
    label: <Trans i18nKey="orders-hub-deep.dinner-in"/>,
    value: "dinner_in",
},
{
    label: <Trans i18nKey='orders-hub-deep.curbside'/>, //"Cubsude",
    value: "is_curbside",
},
{
    label: <Trans i18nKey='orders-hub-deep.delivery'/>,
    value: "is_delivery",
},
{
    label: <Trans i18nKey='orders-hub-deep.no-behavior'/>,
    value: "no_behavior",
},
{
    label: <Trans i18nKey='orders-hub-deep.takeout'/>,
    value: "takeout_in",
},
]
const tabs = [
    { id: 'Open', status:'Open', name: <Trans i18nKey='payment-terminal-deep.open'/>,},
    { id: 'Completed', status:'Completed', name: <Trans i18nKey='payment-terminal-deep.paid'/>,  },
    { id: 'Cancel', status:'Cancel', name: <Trans i18nKey='payment-terminal-deep.cancel'/>,  },
  ]
  
  
const urlApi = 'settings/customers'
const MBar = (props:any) => {
    const { t } = useTranslation()
    const {dataApi,filter,setFilter, isSearch, refetchData,callBackFunc,dataCount} = props
    // const { permission } = getLocationData()
    
    const navigate = useNavigate();
    const { id } = useParams()
    const { valueid } = getValueId(id)
    const { currentUser } = useAuth() as any
    const [edit ,setEdit] = useState<any>(false)
    const [dataQuery ,setDataQuery] = useState<any>([])
    const [orderActive ,setOrderActive] = useState<any>(0)
    const [dataEdit ,setDataEdit] = useState<any>({})
    const [tab, setTab] = useState('Open')
    const [showPayTemplate,setShowPayTemplate] = useState(false)
    const [searchOn,setSearchOn] = useState(false)
    let staffPermissionGroupInfo = useStoreMode((e: any)=> e.info)?.staffPermissionGroupInfo || {}

    
    
    useEffect(() => {
        // console.log('dataApi',dataApi)
        if(dataApi) {
            getData(dataApi)
        }
    }, [dataApi])
    useEffect(() => {
        if(props.filter) {
            console.log('filter',props.filter)
        }
    }, [props.filter])
    const [note,setNote] = useState('')
    const getData = (data: any) => {
        data.forEach((item: any,index: number) => {
            item = converDataWithCustom(item,item.transactionstatus)
            if(!(item.tablename && item.sectionname)) {
                item.tablename = 'Quick Order'
            }
        })
        if(data.length == 0) {
            setDataEdit(null)
            setOrderActive(-1)
        }
        else if(!dataEdit || ( dataEdit && dataEdit.id != data[0]?.id)) {
            handleClickGetItemKOT(data[0],setDataEdit,setNote)
            setOrderActive(0)
        }
        setDataQuery(data)
    
    }
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
    })
    const refetchPayOrder = () => {
        getDetailOrder(dataEdit)
    }
    const toolbar = <>
        <KTButton action="back" onClick={() => navigate(-1)}/>
    </>

    const funcCallback = async(item: any, index: number, status: string) => {
        let tempData = dataQuery.map((i: any) => i)
        // tempData[index] = converDataWithCustom(item,status)
        console.log('ádasda',dataQuery[orderActive])
        try {
            const urlApi = `smarthub/hosptransactions/update/${item.id}`
            await ApiService.put(urlApi, {transactionstatus: status})
            tempData = tempData.filter((i: any,ind: number) => ind != index)
            setDataQuery(tempData)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }

    const checkSearch = (value: any) => {
        return filter[value]
    }
    const getDetailOrder = async(item: any) => {
        if(item) {
            // handleClickGetItemKOT(data[0],setDataEdit)
            // let {data} = await ApiService.get(`smarthub/hosptransactions/${id}?expand=hosptransactionlines,hospitem`) 
            // setDataEdit(data)
            handleClickGetItemKOT(item,setDataEdit,setNote)
            
        }
          
        
    }
    
    const completeOrder = async(item: any,) => {
        try {
            const urlApi = `smarthub/hosptransactions/update/${item.id}`
            await ApiService.put(urlApi, {transactionstatus: 'Completed'})
            // refetchTable && refetchTable()
            // refetchOrderService()
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }
    const filterBtn = <>
    <FilterDropdown
    btnTemplate={<div className="btn btn-light-primary btn-active-primary btn-sm btn-icon">
      <KTSVG path={'/media/icons/custom/filter.svg'} className={clsx('svg-icon-2 svg-icon')} />
    </div>
    }
    width="350"
    onClose={() => {
        // setFilter(undefined)
        reset({})
        }
    }
    handleSubmit={handleSubmit}
    onSubmit={(data:any) => {
      // console.log('ấda',data)
        // setFilter(data)
       
    }} >
        <div className="container-fluid mb-2">
            <div className="row">
              <div className="col-12 fs-5 mb-3">{t('payment-terminal-deep.sort-by')}</div>
              <div className="d-flex gap-3">
                <span className="badge badge-secondary cursor-pointer p-3 mb-3">{t('payment-terminal-deep.check-number')}</span>
                <span className="badge badge-secondary cursor-pointer p-3 mb-3">{t('payment-terminal-deep.most-recent')}</span>
                <span className="badge badge-secondary cursor-pointer p-3 mb-3">{t('payment-terminal-deep.oldest')}</span>
                <span className="badge badge-secondary cursor-pointer p-3 mb-3">{t('payment-terminal-deep.table-number')}</span>
              </div>
              <div className="col-12 fs-5 mb-3">{t('payment-terminal-deep.filter-by')}</div>
              <div className="col-12 col-sm-6">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer flex-start">
                  <input className="form-check-input" type="checkbox" {...register('dinner_in', {
                        onChange: (e) => {} })} value='1' id="dinner_in" />
                  <label htmlFor="dinner_in">{t('payment-terminal-deep.show-check-for-current-user')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('takeout_in', {
                        onChange: (e) => {} })} value={1} id='takeout_in' />
                  <label htmlFor="takeout_in">{t('payment-terminal-deep.takeout-in')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6  mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('is_delivery', {
                        onChange: (e) => {} })} value='1' id='is_delivery' />
                  <label htmlFor="is_delivery">{t('payment-terminal-deep.delivery')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('is_curbside', {
                        onChange: (e) => {} })} value='1' id='is_curbside' />
                  <label htmlFor="is_curbside">{t('payment-terminal-deep.curbside')}</label>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid gap-3 cursor-pointer">
                  <input className="form-check-input" type="checkbox" {...register('no_behavior', {
                        onChange: (e) => {} })} value='1' id='no_behavior' />
                  <label htmlFor="no_behavior">{t('payment-terminal-deep.no-behavior')}</label>
                </div>
              </div>
            </div>
        </div>
      </FilterDropdown>
    </>
    const toolbarAction = <div className="d-flex flex-center gap-5">
        <Dropdown>
            <Dropdown.Toggle as={'div'} className='btn btn-light-primary btn-active-primary btn-sm btn-icon h-el-after'>
            <i className="bi bi-list-ul" />
            </Dropdown.Toggle>
            <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px p-2' >
                
                <Dropdown.Item as='div' className={clsx('menu-item')} >
                    <a href='#' className=''>
                    <span className="menu-link">{t('payment-terminal-deep.manager-online-orders')}</span>
                    </a>
                </Dropdown.Item>
                <Dropdown.Item as='div' className={clsx('menu-item')} >
                    <a href='#' className=''> 
                    <span className="menu-link">{t('payment-terminal-deep.find-checks-and-issue-result')}</span>
                    </a>
                </Dropdown.Item>
                <Dropdown.Item as='div' className={clsx('menu-item')} >
                    <a href='#' className=''> 
                    <span className="menu-link">{t('payment-terminal-deep.switch-users')}</span>
                    </a>
                </Dropdown.Item>
                <Dropdown.Item as='div' className={clsx('menu-item')} >
                    <a href='#' className=''> 
                    <span className="menu-link">{t('payment-terminal-deep.device-status')}</span>
                    </a>
                </Dropdown.Item>
                
            </Dropdown.Menu>
        </Dropdown>
        <span className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer"><i className="bi bi-search"></i></span>
      
    
    </div>
    const getCount = (status: string) => {
        return dataCount?.find((i: any) => i.transactionstatus == status)?.count || 0
      }
    return <>
    <div className="row">
        <div className="col-4">
        <form
            className="h-100 w-100"
            onSubmit={handleSubmit(async (data: any) => {
                console.log('onsubmit',data)
                
            })}>
            <div className="wrap-content-card position-sticky-custom">
                
                {filter &&
                    <div className="card-header min-h-auto pt-4 d-flex gap-2">
                        {
                            badgeSearch.map((item: any) => (
                                checkSearch(item.value) && 
                                <span className="badge badge-gray d-inline-flex flex-center py-2 gap-1"><span>{item.label}</span> <i onClick={e=>{
                                    let a = JSON.parse(JSON.stringify(filter))
                                    a[item.value] = false
                                    setFilter(a)
                                }} className="bi bi-x cursor-pointer"></i></span>
                            ))
                        }
                        
                    </div>
                }
                {
                    dataQuery && dataQuery.length != 0 ?
                    <div className="card-body pb-0">
                        {orderActive > -1 && dataEdit ? 
                            <div className="p-0 card shadow">
                                <h6 className="d-flex flex-stack px-3 pt-3">
                                    <span>#{dataEdit.code} {dataEdit.sectionname}</span>
                                    {/* <span>#{dataEdit.staffcode}</span> */}
                                </h6>
                                
                                <DetailItem {...dataEdit}/>
                                <PromotionAndSum {...dataEdit}/>
                                
                                <div className="d-flex gap-4 mt-3 px-3">
                                    
                                    {
                                        !checkPaid(dataEdit).isPaid && tab != 'Cancel' ?
                                        <>
                                        <button className="btn btn-sm btn-success w-100"><i className="bi bi-printer me-1"></i>{t('order-deep.print')}</button>
                                        {staffPermissionGroupInfo.isopensalepos && 
                                            <button onClick={e=>{
                                                setEdit(true)
                                            }} className="btn btn-sm btn-primary w-100"><i className="bi bi-pencil me-1"></i>{t('payment-terminal-deep.update')}</button>
                                        }
                                        
                                        </>
                                        :
                                        <>
                                        {
                                            tab == 'Cancel' ?<>
                                            {dataQuery[orderActive]?.DiningTableHistoryEntries &&
                                            <div className="btn btn-sm btn-gray my-2 w-100">{t('payment-terminal-deep.closed')} 
                                                <span className="ps-2">{new Date(dataQuery[orderActive]?.DiningTableHistoryEntries[0]?.createddate).toLocaleString('en-GB', { hour12: false })}</span>
                                            </div>}
                                            </> :
                                            <button className="btn btn-sm btn-success w-100"><i className="bi bi-printer me-1"></i>{t('order-deep.print')}</button>
                                        }
                                        </>
                                        // <button onClick={e => completeOrder(dataEdit)} className="btn btn-sm btn-info w-100"><i className="bi bi-printer me-1"></i>Completed</button>

                                    }
                                </div>
                                {
                                    staffPermissionGroupInfo.isaddpayment &&
                                    <div className="p-3">
                                    {
                                        !checkPaid(dataEdit).isPaid && tab != 'Cancel' &&
                                        <button onClick={e => setShowPayTemplate(true)} className="btn btn-sm btn-info w-100"><i className="bi bi-printer me-1"></i>{t('payment-terminal-deep.pay')} {formatCurrent(checkPaid(dataEdit).amount||0)}</button>

                                    }
                                </div>
                                }
                                
                            </div>
                            :
                            <NoDataTemplate className={'h-100 pt-4'}/>
                        }
                      
                    </div>
                    :
                    <NoDataTemplate className={'h-100 pt-4'}/>
                }
                
            </div>
        </form>
        </div>
        <div className="col-8 px-6 py-4">
            <div className="d-flex flex-stack flex-row card  w-100 p-2">
            <div
                className=' d-flex flex-row text-nowrap tab-custom-hub'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-width='auto'
                data-kt-scroll-offset='0'
              >
                {tabs.map((item, index) => {
                  return (<span
                    key={index}
                    id={item.id}
                    onClick={() => {
                      setTab(item.id)
                      callBackFunc(item.status)
                    }}
                    className={clsx(
                      ' cursor-pointer item-tab position-relative pe-8',
                      tab != item.id && 'text-gray-600 ',
                      tab == item.id && 'active',
                      index == 0 && ''
                    )}>
                    {item.name} <span className={clsx(`number-circle bg-${tab == item.id?'primary circle-custom':'gray right-3px'} position-absolute `)}>{getCount(item.status)}</span>
                  </span>)
                })}
              </div>
              {searchOn && <div className="position-relative w-50">
                    <SearchTable data={dataApi} setTableData={setDataQuery} 
                    keySearch={['code','tablename','tableno','saletype','staffcode','grossamount','sectionname',]} />
                    <span className="cursor-pointer close-event-s" onClick={e => {
                        setSearchOn(false)
                        setDataQuery(dataApi)
                    }}>{t('orders-hub-deep.cancel')}</span>
                </div>
                }
              <div className="d-flex gap-2">
                  {filterBtn}
                  <span className="btn btn-light-primary btn-active-primary btn-sm btn-icon cursor-pointer" onClick={e=> setSearchOn(true)}><i className="bi bi-search"></i></span>
              </div>
                
            </div>
                
            <div className="row g-0 mt-4">
                {
                    dataQuery && dataQuery.map((item: any,index: number) =>(
                        <div key={index} className="mb-3 px-1 wrap-item col-6" onClick={e=>{
                            getDetailOrder(item)
                            setOrderActive(index)
                        }}>
                            <div className={clsx('h-100 item bg-primary bg-opacity-10 border-primary p-2 border border-2 d-flex',{'active bg-success border-success': orderActive == index})}>
                                <div className="w-100px px-3 border-right-1 border border-left-0 border border-bottom-0 border border-top-0 border-gray d-flex flex-center">
                                    <div className="text-center">
                                        <span className="fs-3">#{item.code}</span>
                                        <div className="text-center"><span>{item.saletype}</span></div>
                                    </div>
                                </div>
                                <div className="p-2 w-100 wrap-table-inf">
                                    <div className="d-flex flex-stack mb-2">
                                        {
                                            item.tablename && item.sectionname ?
                                            <span>{item.tablename} - {item.sectionname}</span>
                                            :
                                            <span>{item.tablename}</span>
                                        }
                                        <span>{formatCurrent(item.grossamount||0)} đ</span>
                                    </div>
                                    <div className="d-flex gap-2 flex-stack">
                                        <div>
                                        
                                            {
                                                // badge badge-${tab == 'Completed'?'success':'gray'}
                                                tab != 'Open' && item.DiningTableHistoryEntries && <div className={clsx(`mb-1`)}>
                                                    <span className="me-2"><i className="bi bi-check2-circle text-reset"></i>{tab == 'Completed'?' Paid':' Closed'}</span>
                                                    <span>{new Date(item.DiningTableHistoryEntries[0]?.createddate).toLocaleString('en-GB', { hour12: false })}</span>
                                                </div>
                                            }
                                            <span className=""><i className="bi bi-person-hearts"></i> {item.staffcode}</span>
                                        </div>
                                        {
                                            tab == 'Open' ?
                                            <div className="d-flex gap-2 text-gray-600">
                                                <span><i className="bi bi-clock"></i> </span>
                                                <div className="text-center"><CountUpTimer starttime={new Date(`${item.transactiondate} ${item.transactiontime}`).getTime()} /></div>
                                            </div>
                                            :
                                            <>
                                            {
                                                tab == 'Completed' && item.TransactionPaymentEntries && <div className="d-flex gap-2 text-gray-600">
                                                    <span><i className="bi bi-cash"></i> </span>
                                                    <div className="text-center">{item.TransactionPaymentEntries[0]?.tendertypename}</div>
                                                </div>
                                            }
                                            </>
                                            
                                            
                                        }
                                        
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
    {edit && 
        <ModalAddEditOrder refetchTable={refetchData} show={edit} dataModal={dataEdit} refetchCurrent={refetchPayOrder} setShow={setEdit}/>
    }
    <ModalPay show={showPayTemplate} dataModal={dataEdit} refetchCurrent={refetchPayOrder} setShow={setShowPayTemplate}/>
        
    </>
}

export { MBar };

