import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { KTFormItem } from "../../../theme/partials"
import clsx from "clsx"
import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useStoreMode, useStorePrint } from "../../modules/auth/core/store"
import { ApiService, ApiShowError } from "../../../theme/helpers"
import { popupMessage } from "../../modules/messages"
import { getAuth } from "../../modules/auth"
import localforage from 'localforage'
import * as yup from 'yup' 
import { useTranslation } from "react-i18next"
// import { connectEpson } from "./print"

export const AuthLayoutMode = (props: any) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const storeMode = useStoreMode((state:any) => state)
    // const storePrint = useStorePrint((state:any) => state)
    // console.log('storePrint',storePrint)
    const [viewPass, setViewPass] = useState(false)
    const [newViewPass, setNewViewPass] = useState(false)
    const [errorLogin, setErrorLogin] = useState('')
    const {user} = getAuth()||{} as any
    const [yupSchema, setYupSchema] = useState(yup.object().shape({
        code: yup.string().required('Input is required'),
        password: yup.string().required('Input is required'),
    }))
    const { register, setValue, control, reset, watch, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(yupSchema) })
    const {show, setShow} = props
    useEffect(() => {
        if(show) {
            reset({
                code: '',
                password: '',
            })
        }
    },[show])
    // console.log('props',props)
    const [isNewPassword,setIsNewPassword] = useState(false)
    const onSubmit = async(auth: any) => {
        // console.log('auth',auth)

        if(!isNewPassword) {
            auth.newpassword = undefined
        }
        try {
            let api = '/smarthub/auth/login'
            if(isNewPassword) {
                api = '/smarthub/staffs/changepassword'
                // auth = {
                //     staffid: staffInfo."uuid",
                //     password: auth.password,
                //     newpassword: auth.newpassword

                // }
            }
            auth.merchantkey = user?.store?.merchantkey
            auth.storename = user?.store?.storename
            const method = isNewPassword?'put':'post'
            auth.password = auth.password.toLowerCase()
            if(auth.newpassword) {
                auth.newpassword = auth.newpassword.toLowerCase()
            }
            let {data} = await ApiService[method](api,auth)
            if(isNewPassword) {
                setIsNewPassword(false)
                popupMessage({ icon: 'info',title:"Change password success!!!", description: 'Please login again'})
                setYupSchema(yup.object().shape({
                    code: yup.string().required('Input is required'),
                    password: yup.string().required('Input is required'),
                }))

            }
            else {
                getPrinter()
                storeMode.setMode(data)
                setShow(false)
                popupMessage({ icon: 'success', autoClose: true })
                getMenuData(data)
            }
            
            
        } catch (error: any) { 
            let errorCode = error.response?.data?.errorCode
            if( errorCode == '403-284210000') {// invalid token
                navigate('/login')
            }
            else if(errorCode == '400-284210111') {
                setIsNewPassword(true)
                popupMessage({icon: "info",title: error.response?.data?.errorMessage})
                setYupSchema(yup.object().shape({
                    code: yup.string().required('Input is required'),
                    password: yup.string().required('Input is required'),
                    newpassword: yup.string().required('Input is required'),
                }))
            }
            else {
                setErrorLogin(error.response?.data?.errorMessage)
            }
         }
    }
    const getPrinter = async() => {
        try {
            const urlApi = `smarthub/merchants/${user?.store?.merchantid}`
            let {data} = await ApiService.get(urlApi)
            if(data) {
                await localforage.setItem('merchantsData', data)
                // connectEpson(storePrint)
            }
        } catch (error) { ApiShowError(error) }
    }
    const getMenuData = async(record: any) => {
        // console.log('record',record)
        let merchantid = record.staffInfo.merchantid
        try {
            const urlApi = `smarthub/menustructures/find/findformerchantdisplay/${merchantid}`
            // const urlApi = `smarthub/menustructures/3060be35-0795-4495-a6ce-80d4d0e79897?expand=menustructurelines,hostitems `
            let {data} = await ApiService.get(urlApi)
            if(data) {
                localforage.setItem('menudata', data)
            }
            // popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }
    const handleClose = () => {
        setShow(false)
        navigate('/mode')
    }
    return <Modal backdrop="static" keyboard={false} show={show} className='popup-in-popup' onHide={handleClose}>
            <Modal.Header className="p-3">
              <Modal.Title>{t('mode-deep.login-staff')}</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)} className="card card-body">
            <Modal.Body className="p-3">
                    <KTFormItem title={t('mode-deep.code')} isRequired>
                        <input placeholder={t('mode-deep.code')} className={clsx('form-control form-control-sm', { 'form-error': errors.code })} {...register('code')} />
                    </KTFormItem>
                    <KTFormItem title={(isNewPassword ? t('mode-deep.old-password'):t('mode-deep.password'))} isRequired>
                        <div className="position-relative w-100">
                            <input placeholder={t('mode-deep.password')} type={viewPass ? 'text' : 'password'} className={clsx('form-control form-control-sm', { 'form-error': errors.password })} {...register('password')} />
                            <div className='cursor-pointer view-password' onClick={e => setViewPass(!viewPass)}>
                                <i className={clsx(` fs-2 bi bi-eye${viewPass ? '-slash' : ''}`)}></i>
                            </div>
                        </div>
                    </KTFormItem>
                    {
                        isNewPassword && 
                        <KTFormItem title={t('mode-deep.new-password')} isRequired>
                            <div className="position-relative w-100">
                                <input placeholder={t('mode-deep.new-password')} type={newViewPass ? 'text' : 'password'} className={clsx('form-control form-control-sm', { 'form-error': errors.newpassword })} {...register('newpassword')} />
                                <div className='cursor-pointer view-password' onClick={e => setNewViewPass(!newViewPass)}>
                                    <i className={clsx(` fs-2 bi bi-eye${newViewPass ? '-slash' : ''}`)}></i>
                                </div>
                            </div>
                        </KTFormItem>
                    }
                    
                    {
                        errorLogin &&
                        <KTFormItem title=''>
                            {errorLogin && <div className="text-danger">{errorLogin}</div>}
                        </KTFormItem>
                    }
                    
                    
            </Modal.Body>
            <Modal.Footer className="p-3">
              <span className='btn btn-sm btn-secondary' onClick={handleClose}>
                {t('mode-deep.cancel')}
              </span>
              <button className='btn btn-sm btn-primary'>{isNewPassword?t('mode-deep.submit'):t('login')}</button>
            </Modal.Footer>
            </form>
    </Modal>
}