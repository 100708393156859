import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, ApiShowError, getValueId } from "../../../../theme/helpers";
import { KTButton, SearchTable } from "../../../../theme/partials";
import { useAuth } from "../../../modules/auth";
import { popupMessage, popupQuestion } from "../../../modules/messages";
import { checkPaid } from "./activeOrder";
import { converDataWithCustom, handleClickGetItemKOT } from "./functionOrder";
import { DetailItem, HeadItemTemplate, MemberInforAndNote, ModalChooseTable, NoDataTemplate, PromotionAndSum } from "./templateOrder";
import { Trans, useTranslation } from "react-i18next";
const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});
const badgeSearch = [{
    label:  <Trans i18nKey="orders-hub-deep.not-paid"/>,
    value: "notPaid",
},
{
    label:  <Trans i18nKey="orders-hub-deep.paid"/>,
    value: "isPaid",
},
{
    label: <Trans i18nKey="orders-hub-deep.dinner-in"/>,
    value: "dinner_in",
},
{
    label: <Trans i18nKey='orders-hub-deep.curbside'/>, //"Cubsude",
    value: "is_curbside",
},
{
    label: <Trans i18nKey='orders-hub-deep.delivery'/>,
    value: "is_delivery",
},
{
    label: <Trans i18nKey='orders-hub-deep.no-behavior'/>,
    value: "no_behavior",
},
{
    label: <Trans i18nKey='orders-hub-deep.takeout'/>,
    value: "takeout_in",
},
]
const urlApi = 'settings/customers'
const CompleteOrder = (props:any) => {
    const { t } = useTranslation();
    const {dataApi,filter,setFilter, isSearch, setSearchOn,callBackReFetch} = props
    console.log('props',props)
    // const { permission } = getLocationData()
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const { currentUser } = useAuth() as any
    const [dataQuery ,setDataQuery] = useState<any>([])
    const [orderActive ,setOrderActive] = useState<any>(0)
    const [showTable ,setShowTable] = useState(false)
    const [note,setNote] = useState('')
    const handleCloseModalTable = () => {
        setShowTable(false)
    }
        
    useEffect(() => {
        if(dataApi ) {
            getData(dataApi)
        }
    }, [dataApi])
    useEffect(() => {
        if(props.filter) {
            console.log('filter',props.filter)
        }
    }, [props.filter])
    const getData = (data: any) => {
        data.forEach((item: any,index: number) => {
            item = converDataWithCustom(item,item.transactionstatus)
        })
        if(data.length == 0) {
            setDataActive(null)
            setOrderActive(-1)
        }
        else if(!dataActive || (dataActive && dataActive.id != data[0]?.id)) {
            handleClickGetItemKOT(data[0],setDataActive,setNote)
            setOrderActive(0)
        }
        setDataQuery(data)
        // if(data.birthday) {
        //     data.birthday = [new Date(data.birthday)]
        // }
        // if(data.joinDate) {
        //     data.joinDate = [new Date(data.joinDate)]
        // }
        // if(data.officialStartDate) {
        //     data.officialStartDate = [new Date(data.officialStartDate)]
        // }
        // reset(data)
    }
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
    })

    const toolbar = <>
        <KTButton action="back" onClick={() => navigate(-1)}/>
    </>
    const funcCallback = async(item: any, index: number, status: string) => {
        let tempData = dataQuery.map((i: any) => i)
        try {
            const urlApi = `smarthub/hosptransactions/update/${item.id}`
            await ApiService.put(urlApi, {transactionstatus: status})
            callBackReFetch()
            tempData = tempData.filter((i: any,ind: number) => ind != index)
            setDataQuery(tempData)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }

    const checkSearch = (value: any) => {
        return filter[value]
    }
    
    const funcReorder = async(tableInfo: any) => {
        // console.log('tableInfo',tableInfo)
        setShowTable(false)

        let tempData = dataActive || {}
        tempData.tableid = tableInfo.id
        tempData.tablename = tableInfo.tablename
        tempData.tableno = tableInfo.tableno
        tempData.saletype = tableInfo.qrcodeordertype
        
        tempData.sectionid = tableInfo.sectionid
        tempData.sectionname = tableInfo.sectionname
        tempData.sectioncode = tableInfo.sectioncode
        tempData.transactionstatus = 'Open'
        tempData.HospTransactionLines = tempData.HospTransactionLines?.map((i: any) => {
            let temp = i
            temp.kitchenstatus = 'Not Sent'
            delete temp.id
            delete temp.hosptransactionid
            delete temp.transactiondate
            delete temp.transactiontime
            delete i.kotline
            delete temp.ispaid
            return temp
        })
        delete tempData.id
        delete tempData.code
        delete tempData.transactiondate
        delete tempData.transactiontime
        delete tempData.kot
        delete tempData.createdat
        delete tempData.createdby
        delete tempData.modifiedat
        delete tempData.modifiedby
        delete tempData.paid
        delete tempData.TransactionPaymentEntries
        delete tempData.nextStatus
        delete tempData.entryno
        
        // console.log('temData',tempData)
        // return
        try {
            const method = 'post'
            const urlApi = `/smarthub/hosptransactions/upsertwithdetails/`
            let {data} = await ApiService[method](urlApi, tempData)
            // callBackReFetch()
           
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) { ApiShowError(error) }
    }
    const [dataActive,setDataActive] = useState<any>(null)
    return <>
    <div className="row">
        <div className="col-7 ps-0">
        <form
            className="h-100 w-100"
            onSubmit={handleSubmit(async (data: any) => {
                console.log('onsubmit',data)
                return
                if(data.birthday) {
                    data.birthday = new Date(data.birthday[0])
                }
                if(data.joinDate) {
                    data.joinDate = new Date(data.joinDate[0])
                }
                if(data.officialStartDate) {
                    data.officialStartDate = new Date(data.officialStartDate[0])
                }
                let fullName = data.fistName?.trim()+' '+data.middleName?data.middleName?.trim()+' ':''+data.lastName?.trim()
                // data.fullName = fullName && fullName
                // data.flag = valueid ? 'Update' : 'Insert'
                // data.keyID = keyID
                let tempData = {}
                try {
                    const urlApi = `HR_Employee/InsertUpdHR_Employee/`
                    await ApiService.post(urlApi, tempData)
                    popupMessage({ icon: 'success', autoClose: true })
                } catch (error) { ApiShowError(error) }
            })}>
            <div className="wrap-content-card h-100">
                {isSearch && <div className="position-relative mt-4">
                    
                    <SearchTable data={dataQuery} setTableData={setDataQuery} />
                    <span className="cursor-pointer close-event-s" onClick={e => setSearchOn(false)}>{t('orders-hub-deep.cancel')}</span>
                </div>
                }
                {filter &&
                    <div className="card-header min-h-auto pt-4 d-flex gap-2">
                        {
                            badgeSearch.map((item: any) => (
                                checkSearch(item.value) && 
                                <span className="badge badge-gray d-inline-flex flex-center py-2 gap-1"><span>{item.label}</span> <i onClick={e=>{
                                    let a = JSON.parse(JSON.stringify(filter))
                                    a[item.value] = false
                                    setFilter(a)
                                }} className="bi bi-x cursor-pointer"></i></span>
                            ))
                        }
                        
                    </div>
                }
                {
                    dataQuery && dataQuery.length != 0 ?
                    <div className="card-body pb-0 pt-4">
                        {dataQuery?.map((item: any,index: number) =>{
                            return <div className="wrap-item" key={index} onClick={e=>{
                                setOrderActive(index)
                                handleClickGetItemKOT(item,setDataActive,setNote)
                            }}>
                                <HeadItemTemplate item={item} index={index} orderActive={orderActive} isTagPaid={true} checkPaid={checkPaid(item)}/>
                            </div>
                        })}
                    </div>
                    :
                    <NoDataTemplate className={'h-100 pt-4'}/>
                }
                
            </div>
            
            
            
        </form>
        </div>
        <div className="col-5 right-detail px-0">
            {dataActive && orderActive > -1 ? 
                <div className="p-0 card shadow">
                    <h6 className="px-3 pt-3">#{dataActive.tablename}</h6>
                    
                    <DetailItem className="wrap-hub-details" note={note||dataActive.message} {...dataActive} />
                    <div className="wrap-footerItem">
                        <MemberInforAndNote note={note||dataActive.message} {...dataActive} setDataModal={setDataActive} />
                        <PromotionAndSum {...dataActive}/> 
                        
                        <div className="d-flex gap-4 p-3">
                            <button className="btn btn-sm btn-success w-100"><i className="bi bi-printer me-1"></i>{t('order-deep.print')}</button>
                            {/* <button className="btn btn-sm btn-primary w-50"><i className="bi bi-pencil me-1"></i>Edit</button> */}
                            <button onClick={e => setShowTable(true)} className="btn btn-sm btn-info w-100"><i className="bi bi-printer me-1"></i>{t('orders-hub-deep.reorder')}</button>
                        </div>
                    </div>
                </div>
                :
                <NoDataTemplate className={'h-100 pt-4'}/>
            }
            
        </div>
    </div>
    <ModalChooseTable 
        showTable={showTable} 
        handleCloseModal={handleCloseModalTable}
        funcCallback={funcReorder}/> 
    </>
}

export { CompleteOrder };

